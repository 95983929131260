<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Quote details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="submission">Submission</label>
          <InputText id="submission" v-model.trim="entityData.submission_id"  autofocus :class="{'p-invalid': submitted && !entityData.submission_id}" />
        </div>
      </div>

      <div class="field">
        <label for="currency" class="mb-3">Currency</label>
        <Dropdown id="currency" v-model="entityData.currency_id" :options="currencies" option-label="name" placeholder="Select a Currency" option-value="id">
        </Dropdown>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="price">Price</label>
          <InputText id="price" v-model.trim="entityData.price" />
        </div>
      </div>

      <div class="field">
        <label for="data">Data</label>
        <Textarea id="data" v-model="entityData.data" required="true" rows="3" cols="20" />
      </div>

    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import QuoteService from "@/service/QuoteService";
import CurrencyService from "@/service/CurrencyService";

export default {
  name: "QuoteForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean
  },
  currencyService: null,
  entityService: null,
  mounted() {
    this.currencyService.fetchAll().then(data => this.currencies = data.data);
  },

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    }
  },

  created() {
    this.currencyService = new CurrencyService();
    this.entityService = new QuoteService();
  },

  data() {
    return {
      submitted: false,
      currencies: [],
      inputTypes: [],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Quote updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Quote created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>

import http from "@/service/HttpService";
import axios from "axios";

export default class QuoteService {

    fetchAll() {
        return http.get(`quote?include=inputType&per_page=100`).then(d => d.data);
    }

    create(entity) {
        return http.post(`quote`, entity).then(response => response.data);
    }

    update(entity) {
        return http.put(`quote/${entity.id}`, entity).then(response => response.data);
    }

    delete(entity) {
        return http.delete(`quote/${entity.id}`).then(response => response.data);
    }

    bulkDelete(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.delete(`quote/${entity.id}`));
        }

        return axios.all(requests).then()
    }
}
